import React from "react";
import { PageProps } from "gatsby";
import DefaultLayout from "../../layouts/DefaultLayout";
import Image from "../../components/Image";

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout
    pathname={location.pathname}
    pageTitle="Internal & External Doors"
  >
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-sm-10 col-lg-8">
          <Image name="ext_door" />
        </div>
      </div>
    </div>
    <div className="container my-5">
      <p className="text-size-medium">
        We are often asked to make solid timber doors, either for internal or
        external use.
      </p>
      <p className="text-size-medium">
        We can replicate any design. Feel free to send us a diagram of your
        design and we will let you know if it is possible and give you an idea
        of cost.
      </p>
    </div>
    <div className="container my-3">
      <div className="row justify-content-center">
        <div className="col-sm-10 col-md-6 col-lg-3">
          <figure className="figure w-100">
            <Image name="ext_door_1" className="figure-img" />
            <figcaption className="figure-caption">
              External Door, Jarrah
            </figcaption>
          </figure>
        </div>
        <div className="col-sm-10 col-md-6 col-lg-3">
          <figure className="figure w-100">
            <Image name="int_door_1" className="figure-img" />
            <figcaption className="figure-caption">
              Internal Door, Jarrah
            </figcaption>
          </figure>
        </div>
        <div className="col-sm-10 col-md-6 col-lg-3">
          <figure className="figure w-100">
            <Image name="int_door_2" className="figure-img" />
            <figcaption className="figure-caption">
              Internal Door, Rosewood
            </figcaption>
          </figure>
        </div>
        <div className="col-sm-10 col-md-6 col-lg-3">
          <figure className="figure w-100">
            <Image name="int_door_3" className="figure-img" />
            <figcaption className="figure-caption">
              Internal Door, Blackwood
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
